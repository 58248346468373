import { connect } from "react-redux";
import { useParams } from "react-router";

import RecordView from "./record-view";
import { Container } from "@material-ui/core";
import contractorProjectService from "../../services/contractorProjectService";
import { useEffect, useState } from "react";
import { HaulageTicketPayload } from "../../interface/api/HaulageTicketPayload";

const HaulageRecordView = ({}) => {
  const { id }: any = useParams();
  const [haulageTicketPayload, setHaulageTicketPayload] =
    useState<HaulageTicketPayload>();

  useEffect(() => {
    contractorProjectService.getProjectTicket(id).then((response) => {
      if (response.ok) {
        setHaulageTicketPayload(response.data as HaulageTicketPayload);
      }
    });
  }, []);

  return (
    <Container maxWidth="sm">
      <>
        {haulageTicketPayload && (
          <RecordView haulageTicketPayload={haulageTicketPayload} />
        )}
      </>
    </Container>
  );
};

export default connect()(HaulageRecordView);
