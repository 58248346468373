import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Formik } from "formik";
import { connect } from "react-redux";

import { BulkReceiveTicketsFormProps } from "../../../../interface/formik/BulkReceiveTicketsFormProps";
import Button from "../../../shared/Button";
import { BulkReceiveTicketsFormValues } from "../../../../interface/forms/BulkReceiveTicketsFormValues";
import TicketRangeInput from "./ticket-range-input";
import TicketStatusChips from "./ticket-status-chips";

const useStyles = makeStyles({
  resultsItem: { padding: "10px" },
  resultsContainer: {
    marginTop: "10px",
    borderRadius: "5px",
    maxHeight: "400px",
    overflowX: "auto",
  },
});

const BulkReceiveTicketsForm = ({
  onSubmit,
  bulkReceiveTicketsStatus,
  setBulkReceiveTicketsStatus,
}: BulkReceiveTicketsFormProps) => {
  const classes = useStyles();

  const bulkReceiveTicketsFormValues: BulkReceiveTicketsFormValues = {
    ticketRange: "",
    listOfTicketRanges: [],
    forceReceive: false,
  };

  const onForceReceiveClick = (
    values: BulkReceiveTicketsFormValues,
    setFieldValue: Function
  ) => {
    setFieldValue("forceReceive", !values.forceReceive);
    setFieldValue(
      "listOfTicketRanges",
      values.listOfTicketRanges.map((t) => ({
        ...t,
        fail: false,
        success: false,
      }))
    );
    setBulkReceiveTicketsStatus([]);
  };

  return (
    <Formik
      initialValues={bulkReceiveTicketsFormValues}
      onSubmit={(values, { setSubmitting, setFieldValue }) =>
        onSubmit(values, setSubmitting, setFieldValue)
      }
      enableReinitialize={true}
    >
      {({ values, handleSubmit, isSubmitting, resetForm, setFieldValue }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6">SoilFLO</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TicketRangeInput />
              </Grid>
              <Grid item xs={12} md={8}>
                <TicketStatusChips />
                <div
                  className={classes.resultsContainer}
                  style={{
                    backgroundColor: values.forceReceive
                      ? "lightyellow"
                      : "#FFCCCB",
                  }}
                >
                  {bulkReceiveTicketsStatus.map((status, index) => (
                    <Typography key={index} className={classes.resultsItem}>
                      {status}
                    </Typography>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.forceReceive}
                      onChange={() =>
                        onForceReceiveClick(values, setFieldValue)
                      }
                    />
                  }
                  label="Force Receive"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  label={isSubmitting ? "Receiving..." : "Bulk Receive"}
                  type="submit"
                  disabled={
                    isSubmitting ||
                    values.listOfTicketRanges.length === 0 ||
                    values.listOfTicketRanges.every((t) => t.success)
                  }
                  variant="contained"
                  color="primary"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  label={"Clear"}
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    resetForm();
                    setBulkReceiveTicketsStatus([]);
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Formik>
  );
};

export default connect()(BulkReceiveTicketsForm);
