import React, { useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  FormGroup,
  Switch,
  makeStyles,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DataTable, { TableColumn } from "react-data-table-component";
import { ApiResponse } from "apisauce";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import { orderBy as sort } from "lodash";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { getCurrentUser } from "../../store/userStore";
import { GenerateTicketsFormValues } from "../../interface/formik/GenerateTicketsFormValues";
import {
  getTickets,
  setTicketInTickets,
  setTickets,
  setFilterToggleShowActive,
  setFilterToggleShowReceived,
  getShowActive,
  getShowReceived,
  getTodaysTickets,
  getTicketCount,
} from "../../store/ticketStore";
import { SetTicketsAction } from "../../interface/redux-types/SetTicketsAction";
import { Ticket } from "../../interface/api/Ticket";
import { history } from "../..";
import { GenerateTicketsPayload } from "../../interface/api/GenerateTicketsPayload";
import {
  clearContractorCompanyContacts,
  setContractorCompanies,
} from "../../store/contractorStore";
import {
  getTrucks,
  setTransportCompanies,
  setTrucks,
} from "../../store/transportStore";
import { ContractorCompany } from "../../interface/api/ContractorCompany";
import { TransportCompany } from "../../interface/api/TransportCompany";
import { SetContractorCompaniesAction } from "../../interface/redux-types/SetContractorCompaniesAction";
import { SetTransportCompaniesAction } from "../../interface/redux-types/SetTransportCompaniesAction";
import ticketService from "../../services/ticketService";
import contractorCompanyService from "../../services/contractorCompanyService";
import transportCompanyService from "../../services/transportCompanyService";
import { SearchTicketsFormValues } from "../../interface/formik/SearchTicketsFormValues";
import UpdateBulkTicketsForm from "./forms/update-bulk-tickets-form";
import PageHero from "../shared/page-hero";
import { isAdmin, a11yProps } from "../../utils/constants";
import { formatTicketNumber } from "../../utils/parser";
import { TicketPayload } from "../../interface/api/TicketPayload";
import { SetTicketInTicketsAction } from "../../interface/redux-types/SetTicketInTicketsAction";
import { Load } from "../../interface/api/soilflo/Load";
import { UpdateSoilFLOTicketResponse } from "../../interface/api/UpdateSoilFLOTicketResponse";
import { TicketSoilFLOPayload } from "../../interface/api/soilflo/TicketSoilFLOPayload";
import useWindowSize from "../../hooks/useWindowSize";
import { formatDate, formatDateTime } from "../../utils/date-helper";
import scaleService from "../../services/scaleService";
import { MaterialType } from "../../interface/api/MaterialType";
import { SetMaterialTypesAction } from "../../interface/redux-types/SetMaterialTypesAction";
import { setMaterialTypes } from "../../store/scaleStore";
import ReceiveDialog from "../shared/dialog/receive";
import {
  DigitalTicketIcon,
  NotCompleteIcon,
  PrintIcon,
  ReceivedIcon,
  ReceiveIcon,
  SoilFLOIcon,
  TicketEditIcon,
  TicketLogsIcon,
  TruckDispatchIcon,
  ViewHaulageRecordIcon,
} from "../shared/icons";
import GenerateTicketsForm from "./forms/generate-tickets-form";
import TicketsExcelExportForm from "./forms/tickets-excel-export-form";
import { GenerateExcelExportFormValues } from "../../interface/formik/GenerateExcelExportFormValues";
import { GenerateExcelExportPayload } from "../../interface/api/GenerateExcelExportPayload";
import SearchTicketsForm from "./forms/search-tickets-form";
import { ReceiveIncompleteTicketFormValues } from "../../interface/formik/ReceiveIncompleteTicketFormValues";
import { TabPanel } from "../shared/tab-panel";
import truckService from "../../services/truckService";
import { Truck } from "../../interface/api/Truck";
import { SetTrucksAction } from "../../interface/redux-types/SetTrucksAction";
import useFocusSearch from "../../hooks/useFocusSearch";
import useTimeout from "../../hooks/useTimeout";
import { ContractorCompaniesWithThresholdsResponse } from "../../interface/api/ContractorCompaniesWithThresholdsResponse";
import ClearBulkTicketsForm from "./forms/clear-bulk-tickets-form";
import { SearchPayload } from "../../interface/api/SearchPayload";
import { GenerateTicketsResponse } from "../../interface/api/GenerateTicketsResponse";
import BulkReceiveTicketsForm from "./forms/bulk-receive-tickets-form/index";
import { BulkReceiveTicketsPayload } from "../../interface/api/BulkReceiveTicketsPayload";
import { BulkReceiveTicketsFormValues } from "../../interface/forms/BulkReceiveTicketsFormValues";
import { BulkReceiveTicketsPayloadResponse } from "../../interface/api/BulkReceiveTicketsPayloadResponse";

const useStyles = makeStyles({
  metricContainer: { padding: "15px", marginBottom: "10px" },
  metricText: { marginTop: "8px" },
  iconRow: {
    display: "flex",
    justifyContent: "space-around",
    width: "200px",
  },
  refreshLabel: {
    cursor: "pointer",
    textDecoration: "underline",
    fontWeight: "bold",
  },
  overrideLink: {
    textDecoration: "underline",
    fontWeight: "bold",
    border: "2px solid",
    padding: "10px",
    borderRadius: "10px",
    textAlign: "center",
  },
});

const DashboardView = ({}) => {
  enum TabValues {
    Search,
    Generate,
    Export,
    BulkUpdate,
    BulkClear,
    BulkReceive,
  }

  const classes = useStyles();
  const [value, setValue] = useState(TabValues.Search);
  const [open, setOpen] = useState(false);
  const user = useAppSelector(getCurrentUser);
  const tickets = useAppSelector(getTickets);
  const trucks = useAppSelector(getTrucks);
  const showActive = useAppSelector(getShowActive);
  const showReceived = useAppSelector(getShowReceived);
  const dailyTickets = useAppSelector(getTodaysTickets);
  const ticketCount = useAppSelector(getTicketCount);
  const dispatch = useAppDispatch();
  const [pending, setPending] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket>();
  const [selectedTicketSoilFLOData, setSelectedTicketSoilFLOData] =
    useState<Load>();
  const { isMobile } = useWindowSize();
  const [refreshing, setRefreshing] = useState(false);
  const { focusOnSearchInput, doFocusSearchInput } =
    useFocusSearch("ticketNumber");
  const searchFormRef = useRef<any>();
  const [bulkReceiveTicketsStatus, setBulkReceiveTicketsStatus] = useState<
    string[]
  >([]);

  const generateTicketsFormValues: GenerateTicketsFormValues = {
    from: "",
    to: "",
    isSoilFLOTicket: false,
  };

  const generateExcelExportFormValues: GenerateExcelExportFormValues = {
    from: "",
    to: "",
  };

  const searchTicketsFormValues: SearchTicketsFormValues = {
    ticketNumber: "",
    transportCompanyId: 0,
    contractorCompanyId: 0,
    jobSite: "",
    truckLicensePlate: "",
    fromDate: null,
    toDate: null,
  };

  const getLatestActiveTickets = () => {
    setRefreshing(true);

    ticketService
      .getLatestActiveTickets()
      .then((response) => {
        if (response.ok) {
          const tickets = response.data as Ticket[];
          const setTicketsAction: SetTicketsAction = {
            tickets,
          };
          dispatch(setTickets(setTicketsAction));
          setRefreshing(false);
        } else {
          toast.error(response.originalError.message);
        }
      })
      .finally(() => setRefreshing(false));
  };

  useMemo(() => {
    getLatestActiveTickets();
  }, []);

  useMemo(() => {
    dispatch(clearContractorCompanyContacts());

    contractorCompanyService.getContractorCompanies().then((response) => {
      if (response.ok) {
        const contractorCompaniesWithThresholdsResponse =
          response.data as ContractorCompaniesWithThresholdsResponse;
        const contractorCompanies =
          contractorCompaniesWithThresholdsResponse.contractorCompanies as ContractorCompany[];

        const setContractorCompaniesAction: SetContractorCompaniesAction = {
          contractorCompanies: contractorCompanies,
        };

        dispatch(setContractorCompanies(setContractorCompaniesAction));
      } else {
        toast.error(response.originalError.message);
      }
    });

    transportCompanyService.getTransportCompanies().then((response) => {
      if (response.ok) {
        const transportCompanies = response.data as TransportCompany[];
        const setTransportCompaniesAction: SetTransportCompaniesAction = {
          transportCompanies,
        };

        dispatch(setTransportCompanies(setTransportCompaniesAction));
      } else {
        toast.error(response.originalError.message);
      }
    });

    scaleService.getMaterialTypes().then((response) => {
      if (response.ok) {
        const materialTypes = response.data as MaterialType[];
        const setMaterialTypesAction: SetMaterialTypesAction = {
          materialTypes,
        };

        dispatch(setMaterialTypes(setMaterialTypesAction));
      } else {
        toast.error(response.originalError.message);
      }
    });

    truckService
      .getTrucks({
        transportCompanyId: null,
        truck: null,
        transportContact: null,
      })
      .then((response) => {
        if (response.ok) {
          const trucks = response.data as Truck[];
          const SetTrucksAction: SetTrucksAction = {
            trucks,
          };

          dispatch(setTrucks(SetTrucksAction));
        } else {
          toast.error(response.originalError.message);
        }
      });

    return () => {
      setOpen(false);
    };
  }, []);

  useTimeout(() => focusOnSearchInput(), 500);

  const getNumberOfActiveTickets = useMemo(() => {
    return dailyTickets.filter((t) => t.isReceived === false).length;
  }, [dailyTickets]);

  const getNumberOfReceivedTickets = useMemo(() => {
    return dailyTickets.filter((t) => t.isReceived === true).length;
  }, [dailyTickets]);

  const onTabClick = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const onOpen = () => setOpen(true);

  const onClose = () => {
    setSelectedTicket(undefined);
    setSelectedTicketSoilFLOData(undefined);
    setOpen(false);
    doFocusSearchInput();
  };

  const onClear = () => getLatestActiveTickets();

  const onReceiveClick = async (ticketId: number) => {
    //make sure to get the freshest data before receiving
    const response = await ticketService.getTicket(ticketId);

    if (!response.ok) {
      toast.error("Failed to get the lastest ticket data");
      return;
    }

    const ticket = response.data as Ticket;

    if (ticket && ticket.isSoilFLOLoad) {
      ticketService.getLoad(ticket.ticketNumber.toString()).then((response) => {
        if (response.ok) {
          const data = response.data as [Load];

          if (data !== null && data.length > 0) {
            const load = sort(data, (t) => t.id, "desc")?.[0];
            setSelectedTicketSoilFLOData(load);

            try {
              const ticketWithSoilFLOData = { ...ticket };

              ticketWithSoilFLOData.jobSite = load.sourceSite.name
                ? load.sourceSite.name
                : "Unknown";
              ticketWithSoilFLOData.siteContactName = load?.assignerUser
                ? `${load.assignerUser.firstName} ${load.assignerUser.lastName} [${load.assignerUser.phone}]`
                : "Unknown";
              ticketWithSoilFLOData.contractorCompanyName = load.sourceSite
                .contractingcompany
                ? load.sourceSite.contractingcompany.name
                : "Unknown";
              ticketWithSoilFLOData.timeLoaded = load.dispatchedTime;
              ticketWithSoilFLOData.transportCompanyName = load.truck
                .global_truck.hauler
                ? load.truck.global_truck.hauler.name
                : "Unknown";
              ticketWithSoilFLOData.truckDriverName = load.truck.driverName
                ? load.truck.driverName
                : "Unknown";
              ticketWithSoilFLOData.truckLicensePlate = load.truck.global_truck
                .license
                ? load.truck.global_truck.license
                : "Unknown";
              ticketWithSoilFLOData.truckNumber = load.truck.global_truck.number
                ? load.truck.global_truck.number
                : "Unknown";

              setSelectedTicket(ticketWithSoilFLOData);
              onOpen();
            } catch (e) {
              toast.error("SoilFLO data is not correct");
            }
          } else {
            toast.error(
              <>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    {" "}
                    <Typography>
                      No SoilFLO load exists with BoL#:{" "}
                      <b>{ticket.ticketNumber}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      className={classes.overrideLink}
                      onClick={() => {
                        setSelectedTicket({ ...ticket, isSoilFLOLoad: false });
                        onOpen();
                      }}
                    >
                      Override?
                    </div>
                  </Grid>
                </Grid>
              </>,
              {
                autoClose: false,
              }
            );
          }
        } else {
          toast.error("Failed to pull SoilFLO data");
        }
      });
    } else {
      setSelectedTicket(ticket);
      onOpen();
    }
  };

  const onReceiveTicket = (
    receiveIncompleteTicketFormValues: ReceiveIncompleteTicketFormValues | null
  ) => {
    let values = { ...selectedTicket };

    if (receiveIncompleteTicketFormValues) {
      values.jobSite = receiveIncompleteTicketFormValues.jobSite;
      values.siteContactName =
        receiveIncompleteTicketFormValues.siteContactName;
      values.transportCompanyName =
        receiveIncompleteTicketFormValues.transportCompanyName;
      values.truckLicensePlate =
        receiveIncompleteTicketFormValues.truckLicensePlate;
      values.truckNumber = receiveIncompleteTicketFormValues.truckNumber;
      values.truckDriverName =
        receiveIncompleteTicketFormValues.truckDriverName;
    }

    values.timeLoaded = null;
    values.isReceived = true;
    values.timeReceived = new Date();

    if (user) {
      values.userId = user.id ? user.id : null;
    }

    if (values.isSoilFLOLoad && selectedTicketSoilFLOData) {
      let ticketSoilFLOPayload = {
        ...values,
      } as unknown as TicketSoilFLOPayload;

      ticketSoilFLOPayload.updateSoilFLO = true;
      ticketSoilFLOPayload.soilFLOData = selectedTicketSoilFLOData;
      ticketSoilFLOPayload.loggedInUserId = user ? user.id : undefined;

      ticketService.saveSoilFLOTicket(ticketSoilFLOPayload).then((response) => {
        if (response.ok) {
          const updateSoilFLOTicketResponse =
            response.data as UpdateSoilFLOTicketResponse;
          if (updateSoilFLOTicketResponse.success) {
            const ticket = updateSoilFLOTicketResponse.ticket;
            const setTicketInTicketsAction: SetTicketInTicketsAction = {
              ticket,
            };
            dispatch(setTicketInTickets(setTicketInTicketsAction));
            searchFormRef.current?.resetForm();
          } else {
            toast.error("Failed to update SoilFLO ticket!");
          }
        } else {
          toast.error(response.originalError.message);
        }
      });
    } else {
      const ticketPayload: TicketPayload = {
        ticket: values as Ticket,
        loggedInUserId: user ? user.id : undefined,
        createTransportIfDoesNotExist: true,
      };

      ticketService.saveTicket(ticketPayload).then((response) => {
        if (response.ok) {
          const ticket = response.data as Ticket;
          const setTicketInTicketsAction: SetTicketInTicketsAction = {
            ticket,
          };
          dispatch(setTicketInTickets(setTicketInTicketsAction));
          searchFormRef.current?.resetForm();
        } else {
          toast.error(response.originalError.message);
        }
      });
    }

    onClose();
  };

  const onGenerateTicketsFormSubmit = (
    values: GenerateTicketsFormValues,
    setSubmitting: Function
  ) => {
    setPending(true);

    const step = 100;
    let from = Number(values.from);
    let to = Number(values.to);
    let isSoilFLOTicket = values.isSoilFLOTicket;

    if (to - from >= 1000) {
      toast.error(
        "Generate tickets failed. You can only generate max 1000 tickets at a time."
      );
      setPending(false);
      setSubmitting(false);
      return;
    }

    toast.info(
      `Generating tickets from ${from.toString()} to ${to.toString()}`
    );

    const promises: Promise<ApiResponse<unknown, unknown>>[] = [];

    for (let i = from; i < to; i = i + step) {
      let last = i + step - 1 > to ? to : i + step - 1;
      const generateTicketsPayload: GenerateTicketsPayload = {
        from: i,
        to: last,
        isSoilFLOTicket,
        email: "",
      };

      const promise = ticketService.generateTickets(generateTicketsPayload);
      promises.push(promise);
    }

    const errors: string[] = [];

    Promise.all(promises).then((responses) => {
      let hasError = false;
      for (let response of responses) {
        if (response.ok) {
          const data = response.data as GenerateTicketsResponse;

          if (data.success) {
            const newTickets = data.tickets as Ticket[];
            const setTicketsAction: SetTicketsAction = {
              tickets: [...tickets, ...newTickets],
            };

            dispatch(setTickets(setTicketsAction));
          } else {
            hasError = true;
            errors.push(data.error);
          }
        } else {
          hasError = true;
          errors.push("Unexpected error has occurred.");
        }
      }

      if (hasError) {
        for (let error of errors) {
          toast.warning(error, { autoClose: 10000 });
        }
      } else {
        toast.success("Tickets have been generated successfully.");
      }

      setPending(false);
      setSubmitting(false);
    });
  };

  const onTicketsExcelExportFormSubmit = (
    values: GenerateExcelExportFormValues,
    setSubmitting: Function
  ) => {
    var email = user ? user.username : "";

    const generateExcelExportPayload: GenerateExcelExportPayload = {
      from: Number(values.from),
      to: Number(values.to),
      email,
    };

    ticketService
      .exportTicketsExcel(generateExcelExportPayload)
      .then((response) => {
        if (response.ok) {
          var message = `Email with ticket export has been sent to: ${email}`;
          toast.success(message);
        } else {
          const data = response.data as string;
          toast.error(data);
        }

        setSubmitting(false);
      });
  };

  const onSearchTicketsFormSubmit = (
    values: SearchTicketsFormValues,
    setSubmitting: Function
  ) => {
    search(values);
    setSubmitting(false);
  };

  const onSearchTicketNumberChange = (
    values: SearchTicketsFormValues,
    value: string,
    setFieldValue: Function
  ) => {
    setFieldValue("ticketNumber", value);
    if (Number(value)) search({ ...values, ticketNumber: value });
  };

  const onBulkReceiveTicketsFormSubmit = async (
    values: BulkReceiveTicketsFormValues,
    setSubmitting: Function,
    setFieldValue: Function
  ) => {
    for await (let [
      index,
      ticketRange,
    ] of values.listOfTicketRanges.entries()) {
      console.log("ticketRange", ticketRange);

      if (ticketRange.success) continue;

      const payload = {
        ticketRange: ticketRange.ticketRange,
        loggedInUserId: user ? user.id : undefined,
        forceReceive: values.forceReceive,
      } as BulkReceiveTicketsPayload;

      const response = await ticketService.bulkReceiveTickets(payload);
      console.log("response", response.data);

      if (response.ok) {
        const result = response.data as BulkReceiveTicketsPayloadResponse;
        const updatedListOfTicketRanges = [...values.listOfTicketRanges];
        updatedListOfTicketRanges[index].success = result.success;
        updatedListOfTicketRanges[index].fail = result.fail;
        setBulkReceiveTicketsStatus(result.results);
        setFieldValue("listOfTicketRanges", updatedListOfTicketRanges);
      } else {
        const updatedListOfTicketRanges = [...values.listOfTicketRanges];
        updatedListOfTicketRanges[index].fail = true;
        setFieldValue("listOfTicketRanges", updatedListOfTicketRanges);
      }
    }

    setSubmitting(false);
  };

  const onToggleShowActive = () => {
    dispatch(setFilterToggleShowActive());
  };

  const onToggleShowReceived = () => {
    dispatch(setFilterToggleShowReceived());
  };

  const onToggleTicketDispatchLock = (ticketId: number) => {
    const ticket = tickets.find((t) => t.id === ticketId);
    if (!ticket) return;

    const ticketPayload: TicketPayload = {
      ticket: { ...ticket, isDispatched: !ticket.isDispatched },
      loggedInUserId: user ? user.id : undefined,
      createTransportIfDoesNotExist: false,
    };

    ticketService.toggleTicketDispatch(ticketPayload).then((response) => {
      if (response.ok) {
        const ticket = response.data as Ticket;
        const setTicketInTicketsAction: SetTicketInTicketsAction = {
          ticket,
        };
        dispatch(setTicketInTickets(setTicketInTicketsAction));
      }
    });
  };

  const onRefresh = async () => getLatestActiveTickets();

  const onPrint = (ticketId: number) => {
    window.open(
      `/print-record/${ticketId}`,
      "Export Form",
      "width=750,height=750"
    );
  };

  const search = debounce((values: SearchTicketsFormValues) => {
    setRefreshing(true);

    ticketService
      .searchTickets(values as SearchPayload)
      .then((response) => {
        if (response.ok) {
          const tickets = response.data as Ticket[];
          const setTicketsAction: SetTicketsAction = {
            tickets,
          };
          dispatch(setTickets(setTicketsAction));
          setRefreshing(false);
        } else {
          console.error(response.originalError.message);
        }
      })
      .finally(() => setRefreshing(false));
  }, 500);

  const columns: TableColumn<Ticket>[] = [
    {
      name: "",
      width: "125px",
      cell: (row) => {
        if (row.isReceived) return <></>;

        return (
          <ReceiveIcon
            isMobile={isMobile}
            onClick={() => onReceiveClick(row.id)}
          />
        );
      },
    },
    {
      name: "Ticket #",
      selector: (row) =>
        `[${row.prefix}] ${formatTicketNumber(row.ticketNumber)}`,
      sortable: true,
      style: {
        fontSize: isMobile ? "" : "large",
        fontWeight: "bolder",
      },
    },
    {
      name: "Date",
      selector: (row) =>
        showReceived
          ? formatDateTime(row.timeReceived, row.isSoilFLOLoad)
          : formatDateTime(row.timeLoaded, row.isSoilFLOLoad),
      sortable: true,
    },
    {
      name: "Haulage Co.",
      selector: (row) => row.transportCompanyName,
      sortable: true,
    },
    {
      name: "Contractor Company",
      selector: (row) => row.contractorCompanyName,
      sortable: true,
    },
    {
      name: "Job Site",
      selector: (row) => row.jobSite,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            <span title="Ticket Received">
              {row.isReceived && <ReceivedIcon />}
            </span>
            <span title="Ticket Missing Information">
              {!row.isComplete && <NotCompleteIcon />}
            </span>
            <span title="SoilFLO Ticket">
              {row.isSoilFLOLoad && <SoilFLOIcon />}
            </span>
          </>
        );
      },
    },
    {
      name: "",
      width: "250px",
      cell: (row) => {
        return (
          <div className={classes.iconRow}>
            <div>{TruckDispatchIcon(row, onToggleTicketDispatchLock)}</div>
            <div>{TicketEditIcon(row, user)}</div>
            <div>
              <DigitalTicketIcon
                onClick={() => history.push(`/digital-ticket/${row.id}`)}
              />
            </div>
            <div>
              <TicketLogsIcon
                onClick={() => history.push(`/ticket-log/${row.id}`)}
              />
            </div>
            <div>
              <ViewHaulageRecordIcon
                onClick={() => history.push(`/haulage-record/${row.id}`)}
              />
            </div>
            <div>
              <PrintIcon onClick={() => onPrint(row.id)} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Container maxWidth="xl">
      <PageHero
        title="Dashboard"
        subtitle="Generate, Search, and Bulk Update Tickets."
        showBackButton={false}
      />
      <div>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={onTabClick}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            aria-label="scrollable tabs"
          >
            <Tab label="Search" {...a11yProps(0)} />
            {user &&
              isAdmin(user.roleId) && [
                <Tab key="generate" label="Generate" {...a11yProps(1)} />,
                <Tab key="export" label="Export" {...a11yProps(2)} />,
                <Tab key="update" label="Update" {...a11yProps(3)} />,
                <Tab key="clear" label="Clear" {...a11yProps(4)} />,
                <Tab
                  key="bulk-receive"
                  label="Bulk Receive"
                  {...a11yProps(5)}
                />,
              ]}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={TabValues.Search}>
          <Box
            sx={{
              marginY: 8,
            }}
          >
            <SearchTicketsForm
              onSubmit={onSearchTicketsFormSubmit}
              searchTicketsFormValues={searchTicketsFormValues}
              onSearchTicketNumberChange={onSearchTicketNumberChange}
              onClear={onClear}
              searchFormRef={searchFormRef}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={TabValues.Generate}>
          <Box
            sx={{
              marginY: 8,
            }}
          >
            <GenerateTicketsForm
              generateTicketsFormValues={generateTicketsFormValues}
              onSubmit={onGenerateTicketsFormSubmit}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={TabValues.Export}>
          <Box
            sx={{
              marginY: 8,
            }}
          >
            <TicketsExcelExportForm
              generateExcelExportFormValues={generateExcelExportFormValues}
              onSubmit={onTicketsExcelExportFormSubmit}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={TabValues.BulkUpdate}>
          <Box
            sx={{
              marginY: 8,
            }}
          >
            <UpdateBulkTicketsForm setPending={setPending} />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={TabValues.BulkClear}>
          <Box
            sx={{
              marginY: 8,
            }}
          >
            <ClearBulkTicketsForm setPending={setPending} />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={TabValues.BulkReceive}>
          <Box
            sx={{
              marginY: 8,
            }}
          >
            <BulkReceiveTicketsForm
              onSubmit={onBulkReceiveTicketsFormSubmit}
              bulkReceiveTicketsStatus={bulkReceiveTicketsStatus}
              setBulkReceiveTicketsStatus={setBulkReceiveTicketsStatus}
            />
          </Box>
        </TabPanel>
        <div>
          <Paper variant="outlined" className={classes.metricContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6">Metrics</Typography>
              </Grid>
              <Grid item xs={12} md={2} className={classes.metricText}>
                <Typography>Date: {formatDate(new Date(), false)}</Typography>
              </Grid>
              <Grid item xs={12} md={3} className={classes.metricText}>
                <Typography>
                  <b>{dailyTickets.length}</b> Truck(s) Loaded Today
                </Typography>
                <Typography>
                  <b>{ticketCount}</b> Total
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showActive}
                        onChange={() => onToggleShowActive()}
                        name="active"
                        color="primary"
                        size="medium"
                      />
                    }
                    label={
                      <Typography variant="h5">
                        Active ({getNumberOfActiveTickets})
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showReceived}
                        onChange={() => onToggleShowReceived()}
                        name="received"
                        color="primary"
                        size="medium"
                      />
                    }
                    label={
                      <Typography variant="h5">
                        Received ({getNumberOfReceivedTickets})
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={2} className={classes.metricText}>
                <Typography component="div">
                  <Box sx={{ textAlign: "center" }}>
                    {refreshing && <CircularProgress size={20} />}
                    {!refreshing && (
                      <span
                        onClick={() => onRefresh()}
                        className={classes.refreshLabel}
                      >
                        Refresh
                      </span>
                    )}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </div>
        <DataTable
          fixedHeader
          striped
          columns={columns}
          data={tickets}
          pagination
          paginationPerPage={50}
          paginationRowsPerPageOptions={[50, 100, 150, 200]}
          progressPending={pending}
        />
      </div>
      <ReceiveDialog
        open={open}
        onClose={onClose}
        selectedTicket={selectedTicket}
        onReceiveTicket={onReceiveTicket}
        trucks={trucks}
      />
    </Container>
  );
};

export default connect()(DashboardView);
