import { BaseSyntheticEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, MenuItem, Checkbox, FormControlLabel } from "@material-ui/core";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { ApiResponse } from "apisauce";

import { updateBulkTickets } from "../../../store/ticketStore";
import { SetTicketsAction } from "../../../interface/redux-types/SetTicketsAction";
import { Ticket } from "../../../interface/api/Ticket";
import {
  clearContractorProjects,
  getContractorCompanies,
  getContractorCompanyContacts,
  getContractorProjects,
  setContractorCompanyContacts,
  setContractorProjects,
} from "../../../store/contractorStore";
import ticketService from "../../../services/ticketService";
import { UpdateBulkTicketsPayload } from "../../../interface/api/UpdateBulkTicketsPayload";
import { BulkTicketsFormSchema } from "../schema/bulk-tickets-form-schema";
import { ContractorPayload } from "../../../interface/api/ContractorPayload";
import contractorContactService from "../../../services/contractorContactService";
import { ContractorContact } from "../../../interface/api/ContractorContact";
import { SetContractorContactsAction } from "../../../interface/redux-types/SetContractorContactsAction";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { TruckTypes } from "../../../utils/constants";
import { CheckBulkUpdateResponse } from "../../../interface/api/CheckBulkUpdateResponse";
import { getCurrentUser } from "../../../store/userStore";
import { getMaterialTypes } from "../../../store/scaleStore";
import FormErrorMessage from "../../shared/form-error-message";
import FormTextField from "../../shared/form-text-field";
import FormSelect from "../../shared/form-select";
import CheckBulkTicketsDialog from "../../shared/dialog/check-bulk-tickets";
import Button from "../../shared/Button";
import contractorProjectService from "../../../services/contractorProjectService";
import { Project } from "../../../interface/api/Project";
import { SetContractorProjectsAction } from "../../../interface/redux-types/SetContractorProjectsAction";
import FormJobSiteField from "../../shared/form-job-site-field";

const UpdateBulkTicketsForm = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const { setPending } = props;
  const user = useAppSelector(getCurrentUser);
  const contractorCompanies = useAppSelector(getContractorCompanies);
  const contractorCompanyContacts = useAppSelector(
    getContractorCompanyContacts
  );
  const contractorProjects = useAppSelector(getContractorProjects);
  const materialTypes = useAppSelector(getMaterialTypes);
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => {
    setOpen(false);
    setValues(undefined);
  };
  const [values, setValues] = useState<UpdateBulkTicketsPayload>();
  const [response, setResponse] = useState<CheckBulkUpdateResponse>();

  const updateBulkTicketsPayload: UpdateBulkTicketsPayload = {
    from: 0,
    to: 0,
    contractorCompanyId: 0,
    jobSite: "",
    contractorOfficeContactId: 0,
    truckTypeId: 0,
    isSoilFLOTicket: false,
    loggedInUserId: user ? user.id : 0,
    materialTypeId: 0,
    siteContactId: 0,
    projectId: 0,
    isSaltImpacted: false,
    forceUpdate: false,
  };

  useEffect(() => {
    dispatch(clearContractorProjects());
  }, []);

  const checkBulkUpdate = async (values: UpdateBulkTicketsPayload) => {
    if (!values) return;

    let from = Number(values.from);
    let to = values.to ? Number(values.to) : from;

    let updateBulkTicketsPayload = {
      ...values,
      from,
      to,
    } as UpdateBulkTicketsPayload;

    const response = await ticketService.checkBulkUpdate(
      updateBulkTicketsPayload
    );

    if (!response.ok) {
      toast.error("Error checking ticket updates");
      onClose();
    }
    setResponse(response.data as CheckBulkUpdateResponse);
  };

  const onSubmit = async (
    values: UpdateBulkTicketsPayload,
    setSubmitting: Function
  ) => {
    setValues(values);
    await checkBulkUpdate(values);
    onOpen();
    setSubmitting(false);
  };

  const onConfirmSubmit = async (resetForm: Function) => {
    setPending(true);

    if (!values) {
      setPending(false);
      return;
    }

    const step = 100;
    let from = Number(values.from);
    let to = values.to ? Number(values.to) : from;

    toast.info(`Updating tickets from ${from.toString()} to ${to.toString()}`);

    const promises: Promise<ApiResponse<unknown, unknown>>[] = [];

    if (from === to) {
      let loggedInUserId = user ? user.id : undefined;
      let updateBulkTicketsPayload = {
        ...values,
        from,
        to,
        loggedInUserId,
      } as UpdateBulkTicketsPayload;

      const promise = ticketService.updateBulkTickets(updateBulkTicketsPayload);
      promises.push(promise);
    } else {
      for (let i = from; i < to; i = i + step) {
        let last = i + step - 1 > to ? to : i + step - 1;
        let loggedInUserId = user ? user.id : undefined;
        let updateBulkTicketsPayload = {
          ...values,
          from: i,
          to: last,
          loggedInUserId,
        } as UpdateBulkTicketsPayload;

        const promise = ticketService.updateBulkTickets(
          updateBulkTicketsPayload
        );
        promises.push(promise);
      }
    }

    Promise.all(promises).then((responses) => {
      let hasError = false;
      for (let response of responses) {
        if (response.ok) {
          const updatedTickets = response.data as Ticket[];
          const setTicketsAction: SetTicketsAction = {
            tickets: [...updatedTickets],
          };

          dispatch(updateBulkTickets(setTicketsAction));
        }
      }

      setPending(false);

      if (hasError) {
        toast.error("Error occurred updating tickets.");
      } else {
        resetForm();
      }
    });

    onClose();
  };

  const onContractorCompanyChange = (
    value: string,
    setFieldValue: Function
  ) => {
    const contractorCompanyId = Number(value);

    setFieldValue("contractorCompanyId", contractorCompanyId);

    const contractorPayload: ContractorPayload = {
      contractorCompanyId,
      contractorContact: null,
    };

    contractorContactService
      .getContractorContacts(contractorPayload)
      .then((response) => {
        if (response.ok) {
          const contractorContacts = response.data as ContractorContact[];
          const setContractorContactsAction: SetContractorContactsAction = {
            contractorContacts,
          };

          dispatch(setContractorCompanyContacts(setContractorContactsAction));
        } else {
          toast.error(response.originalError.message);
        }
      });

    contractorProjectService
      .getContractorProjects(contractorCompanyId)
      .then((response) => {
        if (response.ok) {
          const projects = response.data as Project[];
          const setProjectsAction: SetContractorProjectsAction = {
            projects,
          };

          dispatch(setContractorProjects(setProjectsAction));
        } else {
          toast.error(response.originalError.message);
        }
      });
  };

  const onContractorProjectChange = (
    e: BaseSyntheticEvent,
    setFieldValue: Function
  ) => {
    setFieldValue("projectId", e.target.value);
    const contractorProject = contractorProjects.find(
      (p) => p.id === e.target.value
    );

    if (contractorProject) {
      setFieldValue("siteContactId", contractorProject.siteContactId ?? 0);
      setFieldValue(
        "contractorOfficeContactId",
        contractorProject.officeContactId ?? 0
      );

      setFieldValue("isSaltImpacted", contractorProject.saltImpacted ?? false);
    }
  };

  return (
    <>
      <Formik
        initialValues={updateBulkTicketsPayload}
        validationSchema={BulkTicketsFormSchema}
        onSubmit={async (values, { setSubmitting }) =>
          onSubmit(values, setSubmitting)
        }
        enableReinitialize={true}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <FormTextField
                    name="from"
                    label="From Ticket Number"
                    value={values.from}
                  />
                  <FormErrorMessage name="from" />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <FormTextField
                    name="to"
                    label="To Ticket Number"
                    value={values.to}
                  />
                  <FormErrorMessage name="to" />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4}>
                  <FormSelect
                    name="contractorCompanyId"
                    label={"Contractor Company"}
                    value={values.contractorCompanyId}
                    onChange={(e: { target: { value: string } }) =>
                      onContractorCompanyChange(e.target.value, setFieldValue)
                    }
                    items={[
                      contractorCompanies.map((contractorCompany, index) => (
                        <MenuItem key={index} value={contractorCompany.id}>
                          {contractorCompany.name}
                        </MenuItem>
                      )),
                    ]}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4}>
                  <FormSelect
                    name="projectId"
                    label={"Project ID"}
                    value={values.projectId ? values.projectId : 0}
                    items={[
                      contractorProjects.map((contractorProject, index) => (
                        <MenuItem key={index} value={contractorProject.id}>
                          {contractorProject.projectId}
                        </MenuItem>
                      )),
                    ]}
                    onChange={(e: BaseSyntheticEvent) =>
                      onContractorProjectChange(e, setFieldValue)
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4}>
                  <FormJobSiteField
                    name="jobSite"
                    label="Job Site"
                    value={values.jobSite}
                    items={
                      values.projectId
                        ? contractorProjects
                            .filter((p) => p.id === values.projectId)
                            .map((c) => c.jobSite)
                        : contractorProjects.map((c) => c.jobSite)
                    }
                    placeholder="Job Site"
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4}>
                  <FormSelect
                    name="siteContactId"
                    label={"Site Contact"}
                    value={values.siteContactId}
                    items={[
                      contractorCompanyContacts
                        .filter((c) => c.isSiteContact)
                        .map((contact, index) => (
                          <MenuItem key={index} value={contact.id}>
                            {contact.name} {contact.number}
                          </MenuItem>
                        )),
                    ]}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4}>
                  <FormSelect
                    name="contractorOfficeContactId"
                    label={"Office Contact"}
                    value={values.contractorOfficeContactId}
                    items={[
                      contractorCompanyContacts
                        .filter((c) => c.isOfficeContact)
                        .map((contact, index) => (
                          <MenuItem key={index} value={contact.id}>
                            {contact.name} {contact.number}
                          </MenuItem>
                        )),
                    ]}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4}>
                  <FormSelect
                    name="truckTypeId"
                    label={"Truck Type"}
                    value={values.truckTypeId}
                    items={[
                      TruckTypes.map((truckType, index) => (
                        <MenuItem key={index} value={truckType.value}>
                          {truckType.label}
                        </MenuItem>
                      )),
                    ]}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4}>
                  <FormSelect
                    name="materialTypeId"
                    label={"Material Type"}
                    value={values.materialTypeId ? values.materialTypeId : 0}
                    items={[
                      materialTypes
                        .filter((c) => c.name)
                        .map((materialType, index) => (
                          <MenuItem key={index} value={materialType.id}>
                            {materialType.name}
                          </MenuItem>
                        )),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isSoilFLOTicket}
                        onChange={() =>
                          setFieldValue(
                            "isSoilFLOTicket",
                            !values.isSoilFLOTicket
                          )
                        }
                        name="isSoilFLOTicket"
                        color="primary"
                      />
                    }
                    label="SoilFLO Ticket/Load"
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isSaltImpacted}
                        onChange={() =>
                          setFieldValue(
                            "isSaltImpacted",
                            !values.isSaltImpacted
                          )
                        }
                        name="isSaltImpacted"
                        color="primary"
                      />
                    }
                    label="Salt Impacted"
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.forceUpdate}
                        onChange={() =>
                          setFieldValue("forceUpdate", !values.forceUpdate)
                        }
                      />
                    }
                    label="Force Update"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Button
                    label={isSubmitting ? "Updating Tickets..." : "Update"}
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                  />
                </Grid>
              </Grid>
            </form>
            <CheckBulkTicketsDialog
              open={open}
              onClose={onClose}
              onConfirmSubmit={onConfirmSubmit}
              response={response}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default connect()(UpdateBulkTicketsForm);
