import http from "./httpService";
import auth from "./authService";
import { ProjectPayload } from "../interface/api/ProjectPayload";
import { GenerateExcelExportPayload } from "../interface/api/GenerateExcelExportPayload";
import { GenerateHaulageRecordReportPayload } from "../interface/api/GenerateHaulageRecordReportPayload";

http.setJwt(auth.getJwt());

export const deleteProject = (id: number | string) => {
  return http.delete(`/api/project/${id}`);
};

export const getProject = (id: number | string) => {
  return http.get(`/api/project/${id}`);
};

export const getProjects = () => {
  return http.get("/api/project/all");
};

export const getContractorProjects = (contractorCompanyId: number) => {
  return http.get(`/api/project/contractor/${contractorCompanyId}`);
};

export function saveProject(projectPayload: ProjectPayload) {
  if (projectPayload && projectPayload.project.id > 0) {
    return http.put("/api/project/edit", projectPayload);
  }
  return http.post("/api/project/new", projectPayload);
}

export const getProjectLogs = (projectId: number | string) => {
  return http.get(`/api/project/logs/${projectId}`);
};

export const getProjectTickets = (
  projectId: number | string,
  generateHaulageRecordReportPayload: GenerateHaulageRecordReportPayload
) => {
  return http.post(
    `/api/project/${projectId}/tickets`,
    generateHaulageRecordReportPayload
  );
};

export const getProjectTicket = (ticketId: number | string) => {
  return http.get(`/api/project/${ticketId}/ticket`);
};

export const generateProjectsReport = () => {
  return http.get(`/api/ticket/report/projects`);
};

export default {
  deleteProject,
  getProject,
  getProjects,
  saveProject,
  getContractorProjects,
  getProjectLogs,
  getProjectTickets,
  getProjectTicket,
  generateProjectsReport,
};
