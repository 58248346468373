import { connect } from "react-redux";
import { useParams } from "react-router";

import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import contractorProjectService from "../../services/contractorProjectService";
import RecordView from "./record-view";
import { HaulageTicketPayload } from "../../interface/api/HaulageTicketPayload";
import { GenerateHaulageRecordReportPayload } from "../../interface/api/GenerateHaulageRecordReportPayload";

const useStyles = makeStyles({
  ticketContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    height: "100vh",
  },
  ticket: {
    width: "25%",
    marginBottom: "2%",
    pageBreakAfter: "always",
  },
});

const HaulageRecordReportView = ({}) => {
  const { projectId }: any = useParams();
  const classes = useStyles();
  const [haulageTicketPayloads, setHaulageTicketPayloads] = useState<
    HaulageTicketPayload[]
  >([]);
  const [ticketsLoaded, setTicketsLoaded] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const generateHaulageRecordReportPayload: GenerateHaulageRecordReportPayload =
      {
        from: params.get("from") as string,
        to: params.get("to") as string,
        fromDate: (params.get("fromDate") as string)
          ? new Date(params.get("fromDate") as string)
          : null,
        toDate: (params.get("toDate") as string)
          ? new Date(params.get("toDate") as string)
          : null,
      };
    console.log(
      "generateHaulageRecordReportPayload",
      generateHaulageRecordReportPayload
    );
    contractorProjectService
      .getProjectTickets(projectId, generateHaulageRecordReportPayload)
      .then((response) => {
        if (response.ok) {
          setHaulageTicketPayloads(response.data as HaulageTicketPayload[]);
          setTicketsLoaded(true);
        }
      });
  }, []);

  useEffect(() => {
    if (ticketsLoaded) {
      const style = document.createElement("style");
      style.innerHTML = `
      @media print {
        @page {
          size: landscape;
          margin: 10mm;
        }

        .ticketContainer {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        .ticket {
          width: 25%;
          margin-bottom: 2%;
          page-break-after: always;
        }

        body {
          margin: 0;
          padding: 0;
        }
      }
    `;
      document.head.appendChild(style);

      setTimeout(() => window.print(), 1000);
    }
  }, [ticketsLoaded]);

  return (
    <>
      {ticketsLoaded ? (
        <div className={classes.ticketContainer}>
          {haulageTicketPayloads &&
            haulageTicketPayloads.map((haulageTicketPayload, index) => (
              <div className={classes.ticket} key={index}>
                <RecordView
                  haulageTicketPayload={haulageTicketPayload}
                  styles={{ marginBottom: 4, showLogo: false, fontSize: 8 }}
                />
              </div>
            ))}
        </div>
      ) : (
        <>Generating...</>
      )}
    </>
  );
};

export default connect()(HaulageRecordReportView);
