import { Chip, Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import { BulkReceiveTicketsFormValues } from "../../../../interface/forms/BulkReceiveTicketsFormValues";
import { FaCheck } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { PiWarningCircleBold } from "react-icons/pi";

const TicketStatusChips = () => {
  const { values } = useFormikContext<BulkReceiveTicketsFormValues>();

  return (
    <Grid container spacing={1}>
      {values.listOfTicketRanges &&
        values.listOfTicketRanges.map((r, index) => {
          const color = r.success
            ? "primary"
            : r.fail
            ? values.forceReceive
              ? "primary"
              : "secondary"
            : "default";

          const icon =
            r.success && !r.fail ? (
              <FaCheck />
            ) : r.fail ? (
              values.forceReceive ? (
                <PiWarningCircleBold />
              ) : (
                <IoMdCloseCircleOutline />
              )
            ) : (
              <></>
            );
          return (
            <Grid item key={index}>
              <Chip label={r.ticketRange} color={color} icon={icon} />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default TicketStatusChips;
