import http from "./httpService";
import auth from "./authService";
import { GenerateTicketsPayload } from "../interface/api/GenerateTicketsPayload";
import { UpdateBulkTicketsPayload } from "../interface/api/UpdateBulkTicketsPayload";
import { TicketPayload } from "../interface/api/TicketPayload";
import { TicketSoilFLOPayload } from "../interface/api/soilflo/TicketSoilFLOPayload";
import { TicketScalePayload } from "../interface/api/TicketScalePayload";
import { GenerateExcelExportPayload } from "../interface/api/GenerateExcelExportPayload";
import { getCurrentUser } from "../services/authService";
import { ClearBulkTicketsPayload } from "../interface/api/ClearBulkTicketsPayload";
import { SearchPayload } from "../interface/api/SearchPayload";
import { GenerateReportPayload } from "../interface/api/GenerateReportPayload";
import { BulkReceiveTicketsPayload } from "../interface/api/BulkReceiveTicketsPayload";

http.setJwt(auth.getJwt());

export const deleteTicket = (id: number | string) => {
  return http.delete(`/api/ticket/${id}`);
};

export const getTicket = (id: number | string, isPublic?: boolean) => {
  const jwt = getCurrentUser();
  if (jwt || isPublic) {
    return http.get(`/api/ticket/${id}`);
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get("hash");
    return http.get(`/api/ticket/${id}/${hash}`);
  }
};

export const getTicketHash = (id: number) => {
  return http.get(`/api/ticket/${id}/hash`);
};

export const getLatestActiveTickets = () => {
  return http.get("/api/ticket/latest/active");
};

export const searchTickets = (searchPayload: SearchPayload) => {
  return http.post("/api/ticket/search", searchPayload);
};

export const getReportingTickets = (
  generateReportPayload: GenerateReportPayload
) => {
  return http.post("/api/ticket/report", generateReportPayload);
};

export const generateTickets = (
  generateTicketsPayload: GenerateTicketsPayload
) => {
  return http.post("/api/ticket/generate", generateTicketsPayload);
};

export const exportTicketsExcel = (
  generateExcelExportPayload: GenerateExcelExportPayload
) => {
  return http.post("/api/ticket/generate/excel", generateExcelExportPayload);
};

export function saveTicket(ticketPayload: TicketPayload) {
  return http.put("/api/ticket/edit", ticketPayload);
}

export function resetTicket(ticketPayload: TicketPayload) {
  return http.put("/api/ticket/reset", ticketPayload);
}

export function weighInTicket(ticketScalePayload: TicketScalePayload) {
  return http.put("/api/ticket/weigh", ticketScalePayload);
}

export function saveShortTicket(ticketPayload: TicketPayload) {
  return http.put("/api/ticket/edit/short", ticketPayload);
}

export function saveSoilFLOTicket(ticketSoilFLOPayload: TicketSoilFLOPayload) {
  return http.put("/api/ticket/edit/soilflo", ticketSoilFLOPayload);
}

export const updateBulkTickets = (
  updateBulkTicketsPayload: UpdateBulkTicketsPayload
) => {
  return http.post("/api/ticket/bulk", updateBulkTicketsPayload);
};

export const clearBulkTickets = (
  clearBulkTicketsPayload: ClearBulkTicketsPayload
) => {
  return http.post("/api/ticket/bulk/clear", clearBulkTicketsPayload);
};

export const checkBulkUpdate = (
  updateBulkTicketsPayload: UpdateBulkTicketsPayload
) => {
  return http.post("/api/ticket/bulk/check", updateBulkTicketsPayload);
};

export const checkClearBulkUpdate = (
  clearBulkTicketsPayload: ClearBulkTicketsPayload
) => {
  return http.post("/api/ticket/bulk/clear/check", clearBulkTicketsPayload);
};

export const getLoad = (number: string) => {
  return http.get(`/api/ticket/load/${number}`);
};

export const getDailyCount = () => {
  return http.get(`/api/ticket/daily/count`);
};

export const getDailyReceived = () => {
  return http.get("/api/ticket/daily/received");
};

export const getDailyLoaded = () => {
  return http.get("/api/ticket/daily/loaded");
};

export const getTicketLogs = (ticketId: number | string) => {
  return http.get(`/api/ticket/logs/${ticketId}`);
};

export const toggleTicketDispatch = (ticketPayload: TicketPayload) => {
  return http.put(`/api/ticket/toggle/dispatch`, ticketPayload);
};

export function bulkReceiveTickets(
  bulkReceiveTicketsPayload: BulkReceiveTicketsPayload
) {
  return http.post("/api/ticket/bulk/receive", bulkReceiveTicketsPayload);
}

export default {
  getTicket,
  deleteTicket,
  generateTickets,
  exportTicketsExcel,
  saveTicket,
  resetTicket,
  weighInTicket,
  updateBulkTickets,
  saveShortTicket,
  getLoad,
  saveSoilFLOTicket,
  checkBulkUpdate,
  getDailyCount,
  getDailyLoaded,
  getDailyReceived,
  getTicketLogs,
  toggleTicketDispatch,
  clearBulkTickets,
  checkClearBulkUpdate,
  getLatestActiveTickets,
  searchTickets,
  getReportingTickets,
  getTicketHash,
  bulkReceiveTickets,
};
