import { makeStyles } from "@material-ui/core";
import { useLocation } from "react-router";
import { LayoutProps } from "../interface/LayoutProps";

import NavMenu from "./shared/nav-menu";

const useStyles = makeStyles({
  footer: {
    marginTop: 40,
  },
});

const Layout = ({ children }: LayoutProps) => {
  const classes = useStyles();
  const location = useLocation();

  const removeLayout =
    location.pathname.includes("print-record") ||
    location.pathname.includes("report/haulage-record");

  if (removeLayout) {
    return <>{children}</>;
  }

  return (
    <>
      <NavMenu />
      {children}
      <footer className={classes.footer} />
    </>
  );
};

export default Layout;
