import { Formik } from "formik";
import { useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Container, Grid, MenuItem } from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import { history } from "../../..";
import { getUser, setUser } from "../../../store/userStore";
import userService from "../../../services/userService";
import { SetUserAction } from "../../../interface/redux-types/SetUserAction";
import { User } from "../../../interface/api/User";
import { UserFormValues } from "../../../interface/formik/UserFormValues";
import { UserFormSchema } from "../schema/user-form-schema";
import { Roles } from "../../../utils/constants";
import PageHero from "../../shared/page-hero";
import FormErrorMessage from "../../shared/form-error-message";
import FormTextField from "../../shared/form-text-field";
import FormSelect from "../../shared/form-select";
import Button from "../../shared/Button";
import FormPasswordField from "../../shared/form-password-field";

const UserView = ({}) => {
  const { id }: any = useParams();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);

  useMemo(() => {
    if (id > 0) {
      userService.getUser(id).then((response) => {
        if (response.ok) {
          const user = response.data as User;
          const setUserAction: SetUserAction = {
            user,
          };
          dispatch(setUser(setUserAction));
        } else {
          toast.error(response.originalError.message);
        }
      });
    }
  }, []);

  const onSubmit = (values: UserFormValues, setSubmitting: Function) => {
    userService.saveUser(values as User).then((response) => {
      if (response.ok) {
        history.push(`/users`);
      } else {
        const data = response.data as string;
        toast.error(data);
        setSubmitting(false);
      }
    });

    setSubmitting(false);
  };

  const initialValues: UserFormValues = user
    ? user
    : {
        id: 0,
        username: "",
        firstName: "",
        lastName: "",
        password: "",
        roleId: 2,
        phoneNumber: "",
      };

  return (
    <Container maxWidth="xl">
      <PageHero
        title={id > 0 ? `User: ${id}` : "New User"}
        subtitle={id > 0 ? `Edit User.` : "Add User."}
        showBackButton={true}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={UserFormSchema}
        onSubmit={(values, { setSubmitting }) =>
          onSubmit(values, setSubmitting)
        }
        enableReinitialize={true}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="username"
                  type="email"
                  label={"Username"}
                  dontCapitalize={true}
                  value={values.username}
                />
                <FormErrorMessage name="username" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="firstName"
                  label={"First Name"}
                  value={values.firstName}
                />
                <FormErrorMessage name="firstName" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="lastName"
                  label={"Last Name"}
                  value={values.lastName}
                />
                <FormErrorMessage name="lastName" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormPasswordField
                  name="password"
                  label="Password"
                  value={values.password}
                />
                <FormErrorMessage name="password" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormSelect
                  name="roleId"
                  label={"Role"}
                  value={values.roleId}
                  items={[
                    Roles.map((role, index) => (
                      <MenuItem key={index} value={role.value}>
                        {role.label}
                      </MenuItem>
                    )),
                  ]}
                  onChange={(e: { target: { value: string } }) =>
                    setFieldValue("roleId", Number(e.target.value))
                  }
                />
                <FormErrorMessage name="roleId" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="phoneNumber"
                  label={"Phone Number"}
                  value={values.phoneNumber}
                />
                <FormErrorMessage name="phoneNumber" />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  label="Save"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default connect()(UserView);
