import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  DialogActions,
} from "@material-ui/core";

import Button from "../Button";
import { HaulageRecordDialogProps } from "../../../interface/dialog/HaulageRecordDialogProps";
import { Formik } from "formik";
import { GenerateReportFormSchema } from "../../reports/schema/generate-report-form-schema";
import FormDatePicker from "../form-date-picker";
import FormTextField from "../form-text-field";
import { GenerateHaulageRecordReportFormValues } from "../../../interface/formik/GenerateHaulageRecordReportFormValues";

const useStyles = makeStyles({
  dialogActions: {
    padding: "20px",
  },
});

const HaulageRecordReportDialog = ({
  open,
  onClose,
  onSubmit,
}: HaulageRecordDialogProps) => {
  const classes = useStyles();

  const generateReportFormValues: GenerateHaulageRecordReportFormValues = {
    fromDate: null,
    toDate: null,
    to: "",
    from: "",
  };

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>{"Haulage Record Report"}</DialogTitle>
      <Formik
        initialValues={generateReportFormValues}
        onSubmit={(values, { setSubmitting }) =>
          onSubmit(values, setSubmitting)
        }
        validationSchema={GenerateReportFormSchema}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormTextField
                    name="from"
                    label="From Ticket Number"
                    value={values.from}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    name="to"
                    label="To Ticket Number"
                    value={values.to}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormDatePicker
                    label="From Date"
                    value={values.fromDate}
                    onChange={(date: any) => setFieldValue("fromDate", date)}
                    clearable
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormDatePicker
                    label="To Date"
                    value={values.toDate}
                    onChange={(date: any) => setFieldValue("toDate", date)}
                    clearable
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Grid item xs={6}>
                <Button
                  label="Cancel"
                  color="secondary"
                  variant="contained"
                  type="button"
                  onClick={() => onClose()}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  label="Generate"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                />
              </Grid>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default HaulageRecordReportDialog;
