import { TextField, Typography, makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import { BulkReceiveTicketsFormValues } from "../../../../interface/forms/BulkReceiveTicketsFormValues";
import { ChangeEvent, useEffect, useState } from "react";

const useStyles = makeStyles({
  textField: { width: "100%" },
});

const TicketRangeInput = () => {
  const classes = useStyles();
  const [error, setError] = useState("");
  const { setFieldValue, values } =
    useFormikContext<BulkReceiveTicketsFormValues>();

  const onTicketRangeEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const regex = /^\d+([,-]\d+)*$/;

    if (e.key === "Enter") {
      e.preventDefault();

      const ticketRange = values.ticketRange;

      let valid = true;

      if (!regex.test(ticketRange)) {
        setError("Invalid ticket range formt (e.g. 1,2,3 or 1-3)");
        valid = false;
      }

      if (ticketRange.includes("-")) {
        ticketRange.split(",").forEach((t) => {
          let range = t.split("-").map((t) => parseInt(t));
          console.log("range", range);

          if (range[1] < range[0]) {
            setError("End of range must be greater than start of range");
            valid = false;
          } else if (range[1] - range[0] >= 100) {
            setError("Ticket range is too large (max 100)");
            valid = false;
          }
        });
      }

      if (valid) {
        setFieldValue("listOfTicketRanges", [
          ...values.listOfTicketRanges,
          { ticketRange: values.ticketRange, success: false, fail: false },
        ]);
      }

      setFieldValue("ticketRange", "");
    }
  };

  const onTicketRangeChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setError("");
    setFieldValue("ticketRange", e.target.value);
  };

  return (
    <>
      <TextField
        className={classes.textField}
        value={values.ticketRange}
        onChange={(e) => onTicketRangeChange(e)}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
          onTicketRangeEnter(e)
        }
        label={"Ticket Number Selection"}
        placeholder="Enter ticket number(s) separated by comma or -"
        variant="outlined"
      />
      {error && (
        <Typography color="error" variant="subtitle2">
          * {error}
        </Typography>
      )}
    </>
  );
};

export default TicketRangeInput;
