import { useMemo } from "react";
import { connect } from "react-redux";
import { Container, Grid, makeStyles } from "@material-ui/core";
import DataTable, { TableColumn } from "react-data-table-component";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { getReportTickets, setTickets } from "../../store/ticketStore";
import { Ticket } from "../../interface/api/Ticket";
import { toast } from "react-toastify";
import { setContractorCompanies } from "../../store/contractorStore";
import { setTransportCompanies } from "../../store/transportStore";
import { ContractorCompany } from "../../interface/api/ContractorCompany";
import { TransportCompany } from "../../interface/api/TransportCompany";
import { SetContractorCompaniesAction } from "../../interface/redux-types/SetContractorCompaniesAction";
import { SetTransportCompaniesAction } from "../../interface/redux-types/SetTransportCompaniesAction";
import contractorCompanyService from "../../services/contractorCompanyService";
import transportCompanyService from "../../services/transportCompanyService";
import PageHero from "../shared/page-hero";
import {
  formatDate,
  formatDateTime,
  formatTime,
} from "../../utils/date-helper";
import Button from "../shared/Button";
import { ReceivedIcon, SoilFLOIcon } from "../shared/icons";
import { ContractorCompaniesWithThresholdsResponse } from "../../interface/api/ContractorCompaniesWithThresholdsResponse";
import GenerateReportForm from "./forms/generate-report-form";
import { GenerateReportFormValues } from "../../interface/formik/GenerateReportFormValues";
import ticketService from "../../services/ticketService";
import { SetTicketsAction } from "../../interface/redux-types/SetTicketsAction";

const useStyles = makeStyles({
  container: {
    marginBottom: "25px",
  },
});

const ReportsView = ({}) => {
  const classes = useStyles();
  const tickets = useAppSelector(getReportTickets);
  const dispatch = useAppDispatch();

  useMemo(() => {
    contractorCompanyService.getContractorCompanies().then((response) => {
      if (response.ok) {
        const contractorCompaniesWithThresholdsResponse =
          response.data as ContractorCompaniesWithThresholdsResponse;
        const contractorCompanies =
          contractorCompaniesWithThresholdsResponse.contractorCompanies as ContractorCompany[];

        const setContractorCompaniesAction: SetContractorCompaniesAction = {
          contractorCompanies: contractorCompanies,
        };

        dispatch(setContractorCompanies(setContractorCompaniesAction));
      } else {
        toast.error(response.originalError.message);
      }
    });

    transportCompanyService.getTransportCompanies().then((response) => {
      if (response.ok) {
        const transportCompanies = response.data as TransportCompany[];
        const setTransportCompaniesAction: SetTransportCompaniesAction = {
          transportCompanies,
        };

        dispatch(setTransportCompanies(setTransportCompaniesAction));
      } else {
        toast.error(response.originalError.message);
      }
    });
  }, []);

  const onExport = () => {
    let csvData: any[] = [
      {
        TotalTickets: tickets && tickets.length,
      },
    ];

    tickets.forEach((ticket: Ticket) => {
      csvData.push({
        received: ticket.isReceived ? "yes" : "no",
        ticketNumber: ticket.ticketNumber,
        jobSite: ticket.jobSite,
        projectId: ticket.projectProjectId,
        contractorCompanyName: ticket.contractorCompanyName,
        siteContactName: ticket.siteContactName,
        siteContactNumber: ticket.siteContactNumber,
        contractorOfficeContactName: ticket.contractorOfficeContactName,
        contractorOfficeContactNumber: ticket.contractorOfficeContactNumber,
        contractorOfficeContactEmail: ticket.contractorOfficeContactEmail,
        date: formatDate(ticket.timeReceived, ticket.isSoilFLOLoad),
        timeLoaded: formatDateTime(ticket.timeLoaded, ticket.isSoilFLOLoad),
        soilFLOLoad: ticket.isSoilFLOLoad ? "yes" : "no",
        projectLeader: ticket.projectLeader,
        transportCompanyName: ticket.transportCompanyName,
        transportContactName: ticket.transportContactName,
        transportContactNumber: ticket.transportContactNumber,
        truckTypeName: ticket.truckTypeName,
        materialTypeName: ticket.materialTypeName,
        material: ticket.materialName,
        weight: ticket.weight,
        truckDriverName: ticket.truckDriverName,
        truckDriverNumber: ticket.truckDriverNumber,
        truckLicensePlate: ticket.truckLicensePlate,
        truckNumber: ticket.truckNumber,
        contactName: `${ticket.userFirstName} ${ticket.userLastName}`,
        timeReceived: formatDateTime(ticket.timeReceived, false),
        notes: ticket.notes,
        soilFLOTicketId: ticket.soilFLOTicketId,
      });
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `tickets-export` + fileExtension);
  };

  const columns: TableColumn<Ticket>[] = [
    {
      name: "Received",
      cell: (row) => {
        return <span>{row.isReceived && <ReceivedIcon />}</span>;
      },
    },
    {
      name: "Ticket #",
      selector: (row) => row.ticketNumber,
      sortable: true,
    },
    {
      name: "Job Site",
      selector: (row) => row.jobSite,
      sortable: true,
    },
    {
      name: "Project ID",
      selector: (row) => row.projectProjectId,
      sortable: true,
    },
    {
      name: "Contractor Company",
      selector: (row) => row.contractorCompanyName,
      sortable: true,
    },
    {
      name: "Site Contact Name",
      selector: (row) => row.siteContactName,
      sortable: true,
    },
    {
      name: "Site Contact Number",
      selector: (row) => row.siteContactNumber,
      sortable: true,
    },
    {
      name: "Office Contact",
      selector: (row) => row.contractorOfficeContactName,
      sortable: true,
    },
    {
      name: "Office Contact Number",
      selector: (row) => row.contractorOfficeContactNumber,
      sortable: true,
    },
    {
      name: "Office Contact Email",
      selector: (row) => row.contractorOfficeContactEmail,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) =>
        row.timeLoaded ? new Date(row.timeLoaded).toLocaleDateString() : "",
      sortable: true,
    },
    {
      name: "Time Loaded",
      selector: (row) => formatTime(row.timeLoaded, row.isSoilFLOLoad),
      sortable: true,
    },
    {
      name: "SoilFLO Load",
      cell: (row) => {
        return <span>{row.isSoilFLOLoad && <SoilFLOIcon />}</span>;
      },
    },
    {
      name: "Project Leader",
      selector: (row) => row.projectLeader,
      sortable: true,
    },
    {
      name: "Haulage Co.",
      selector: (row) => row.transportCompanyName,
      sortable: true,
    },
    {
      name: "Transport Contact",
      selector: (row) => row.transportContactName,
      sortable: true,
    },
    {
      name: "Transport Contact Number",
      selector: (row) => row.transportContactNumber,
      sortable: true,
    },
    {
      name: "Truck Type",
      selector: (row) => row.truckTypeName,
      sortable: true,
    },
    {
      name: "Material Type Name",
      selector: (row) => (row.materialTypeName ? row.materialTypeName : ""),
      sortable: true,
    },
    {
      name: "Material",
      selector: (row) => (row.materialName ? row.materialName : ""),
      sortable: true,
    },
    {
      name: "Weight",
      selector: (row) => (row.weight ? row.weight : ""),
      sortable: true,
    },
    {
      name: "Truck Driver",
      selector: (row) => row.truckDriverName,
      sortable: true,
    },
    {
      name: "Truck Driver Number",
      selector: (row) => row.truckDriverNumber,
      sortable: true,
    },
    {
      name: "Truck License Plate",
      selector: (row) => row.truckLicensePlate,
      sortable: true,
    },
    {
      name: "Truck #",
      selector: (row) => (Number(row.truckNumber) > 0 ? row.truckNumber : ""),
      sortable: true,
    },
    {
      name: "Contact Name",
      selector: (row) =>
        row.userFirstName && row.userLastName
          ? `${row.userFirstName} ${row.userLastName}`
          : "",
      sortable: true,
    },
    {
      name: "Time Received",
      selector: (row) => formatTime(row.timeReceived, false),
      sortable: true,
    },
    {
      name: "Notes",
      selector: (row) => row.notes,
      sortable: false,
    },
    {
      name: "SoilFLOTicketId",
      selector: (row) => (row.soilFLOTicketId ? row.soilFLOTicketId : ""),
      sortable: true,
    },
  ];

  const generateReportFormValues: GenerateReportFormValues = {
    transportCompanyId: 0,
    contractorCompanyId: 0,
    jobSite: "",
    fromDate: null,
    toDate: null,
    truckLicensePlate: "",
    from: "",
    to: "",
    projectId: "",
  };

  const onSubmit = (
    values: GenerateReportFormValues,
    setSubmitting: Function
  ) => {
    ticketService
      .getReportingTickets(values)
      .then((response) => {
        if (response.ok) {
          const tickets = response.data as Ticket[];
          const setTicketsAction: SetTicketsAction = {
            tickets,
          };
          dispatch(setTickets(setTicketsAction));
        } else {
          toast.error(response.originalError.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Container maxWidth="xl">
      <PageHero
        title="Reports"
        subtitle="Manage Reports."
        showBackButton={false}
      />
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs>
          <GenerateReportForm
            onSubmit={onSubmit}
            generateReportFormValues={generateReportFormValues}
          />
        </Grid>
      </Grid>
      <DataTable
        fixedHeader
        striped
        columns={columns}
        data={tickets}
        pagination
        paginationPerPage={50}
        paginationRowsPerPageOptions={[50, 100, 150, 200]}
      />
      <div className="sticky-footer">
        <Grid item xs={12} md={3}>
          <Button
            label="Export"
            color="secondary"
            variant="contained"
            type="button"
            onClick={() => onExport()}
          />
        </Grid>
      </div>
    </Container>
  );
};

export default connect()(ReportsView);
