import { connect } from "react-redux";
import { Box, Grid, MenuItem } from "@material-ui/core";
import { Formik } from "formik";

import FormTextField from "../../shared/form-text-field";
import FormSelect from "../../shared/form-select";
import Button from "../../shared/Button";
import FormDatePicker from "../../shared/form-date-picker";
import { GenerateReportFormProps } from "../../../interface/forms/GenerateReportFormProps";
import { useAppSelector } from "../../../hooks";
import { getContractorCompanies } from "../../../store/contractorStore";
import { getTransportCompanies } from "../../../store/transportStore";
import { GenerateReportFormSchema } from "../schema/generate-report-form-schema";

const GenerateReportForm = ({
  generateReportFormValues,
  onSubmit,
}: GenerateReportFormProps) => {
  const contractorCompanies = useAppSelector(getContractorCompanies);
  const transportCompanies = useAppSelector(getTransportCompanies);

  return (
    <Formik
      initialValues={generateReportFormValues}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      validationSchema={GenerateReportFormSchema}
    >
      {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <FormTextField
                name="from"
                label="From Ticket Number"
                value={values.from}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <FormTextField
                name="to"
                label="To Ticket Number"
                value={values.to}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormDatePicker
                label="From Date"
                value={values.fromDate}
                onChange={(date: any) => setFieldValue("fromDate", date)}
                clearable
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormDatePicker
                label="To Date"
                value={values.toDate}
                onChange={(date: any) => setFieldValue("toDate", date)}
                clearable
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormSelect
                name="contractorCompanyId"
                label={"Contractor Company"}
                value={values.contractorCompanyId}
                items={[
                  contractorCompanies.map((contractorCompany, index) => (
                    <MenuItem key={index} value={contractorCompany.id}>
                      {contractorCompany.name}
                    </MenuItem>
                  )),
                ]}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormSelect
                name="transportCompanyId"
                label={"Haulage Co."}
                value={values.transportCompanyId}
                items={[
                  transportCompanies.map((transportCompany, index) => (
                    <MenuItem key={index} value={transportCompany.id}>
                      {transportCompany.name}
                    </MenuItem>
                  )),
                ]}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  mb: {
                    xs: "20px",
                    md: 0,
                  },
                }}
              >
                <FormTextField
                  name="jobSite"
                  label="Search Job Site"
                  value={values.jobSite}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  mb: {
                    xs: "20px",
                    md: 0,
                  },
                }}
              >
                <FormTextField
                  name="projectId"
                  label="Project ID"
                  value={values.projectId}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                label="Generate"
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default connect()(GenerateReportForm);
