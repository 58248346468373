import { Formik } from "formik";
import { useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import {
  Container,
  Grid,
  FormControlLabel,
  Checkbox,
  MenuItem,
  TextareaAutosize,
  makeStyles,
} from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ContractorCompany } from "../../../interface/api/ContractorCompany";
import { ContractorContact } from "../../../interface/api/ContractorContact";
import { ContractorPayload } from "../../../interface/api/ContractorPayload";
import { SetContractorCompanyAction } from "../../../interface/redux-types/SetContractorCompanyAction";
import contractorCompanyService from "../../../services/contractorCompanyService";
import contractorContactService from "../../../services/contractorContactService";
import {
  getContractorCompany,
  getContractorCompanyContacts,
  getContractorProject,
  setContractorCompany,
  setContractorCompanyContacts,
  setContractorProject,
} from "../../../store/contractorStore";
import PageHero from "../../shared/page-hero";
import FormErrorMessage from "../../shared/form-error-message";
import FormTextField from "../../shared/form-text-field";
import Button from "../../shared/Button";
import projectService from "../../../services/contractorProjectService";
import { Project } from "../../../interface/api/Project";
import { SetContractorProjectAction } from "../../../interface/redux-types/SetContractorProjectAction";
import { ContractorProjectFormValues } from "../../../interface/formik/ContractorProjectFormValues";
import { ContractorProjectFormSchema } from "../schema/contractor-project-form-schema";
import { SetContractorContactsAction } from "../../../interface/redux-types/SetContractorContactsAction";
import FormSelect from "../../shared/form-select";
import { getMaterialTypes, setMaterialTypes } from "../../../store/scaleStore";
import { MaterialType } from "../../../interface/api/MaterialType";
import { SetMaterialTypesAction } from "../../../interface/redux-types/SetMaterialTypesAction";
import scaleService from "../../../services/scaleService";
import contractorProjectService from "../../../services/contractorProjectService";
import { history } from "../../..";
import { ProjectPayload } from "../../../interface/api/ProjectPayload";
import { getCurrentUser } from "../../../store/userStore";

const useStyles = makeStyles({
  textArea: { width: "100%", fontSize: "16px" },
});

const ContractorProjectView = ({}) => {
  const { id, companyId }: any = useParams();
  const dispatch = useAppDispatch();
  const contractorCompany = useAppSelector(getContractorCompany);
  const contractorCompanyContacts = useAppSelector(
    getContractorCompanyContacts
  );
  const project = useAppSelector(getContractorProject);
  const materialTypes = useAppSelector(getMaterialTypes);
  const user = useAppSelector(getCurrentUser);
  const classes = useStyles();

  useMemo(() => {
    if (id > 0) {
      projectService.getProject(id).then((response) => {
        if (response.ok) {
          const project = response.data as Project;
          const setProjectAction: SetContractorProjectAction = {
            project,
          };
          dispatch(setContractorProject(setProjectAction));
        } else {
          toast.error(response.originalError.message);
        }
      });
    }
  }, []);

  useMemo(() => {
    if (companyId > 0) {
      contractorCompanyService
        .getContractorCompany(companyId)
        .then((response) => {
          if (response.ok) {
            const contractorCompany = response.data as ContractorCompany;
            const setContractorCompanyAction: SetContractorCompanyAction = {
              contractorCompany: contractorCompany,
            };

            dispatch(setContractorCompany(setContractorCompanyAction));
          } else {
            toast.error(response.originalError.message);
          }
        });

      const contractorPayload: ContractorPayload = {
        contractorCompanyId: companyId,
        contractorContact: null,
      };

      contractorContactService
        .getContractorContacts(contractorPayload)
        .then((response) => {
          if (response.ok) {
            const contractorContacts = response.data as ContractorContact[];
            const setContractorContactsAction: SetContractorContactsAction = {
              contractorContacts,
            };

            dispatch(setContractorCompanyContacts(setContractorContactsAction));
          } else {
            toast.error(response.originalError.message);
          }
        });
    }
  }, []);

  useMemo(() => {
    scaleService.getMaterialTypes().then((response) => {
      if (response.ok) {
        const materialTypes = response.data as MaterialType[];
        const setMaterialTypesAction: SetMaterialTypesAction = {
          materialTypes,
        };

        dispatch(setMaterialTypes(setMaterialTypesAction));
      } else {
        toast.error(response.originalError.message);
      }
    });
  }, []);

  const onSubmit = (
    values: ContractorProjectFormValues,
    setSubmitting: Function
  ) => {
    if (!user) {
      toast.error("User must be logged in.");
      return;
    }

    const projectPayload: ProjectPayload = {
      project: { ...values },
      loggedInUserId: user.id,
    };

    contractorProjectService.saveProject(projectPayload).then((response) => {
      if (response.ok) {
        history.goBack();
      } else {
        const data = response.data as string;
        toast.error(data);
        setSubmitting(false);
      }
    });

    setSubmitting(false);
  };

  const initialValues: ContractorProjectFormValues = project
    ? project
    : {
        id: 0,
        projectId: "",
        contractorCompanyId: contractorCompany ? contractorCompany.id : 0,
        contractorCompanyName: contractorCompany ? contractorCompany.name : "",
        jobSite: "",
        siteContactId: 0,
        siteContactName: "",
        siteContactNumber: "",
        officeContactId: 0,
        officeContactName: "",
        officeContactNumber: "",
        materialTypeId: 0,
        materialTypeName: "",
        isSoilFLOProject: false,
        ticketsSold: 0,
        approved: 0,
        overThreshold: false,
        saltImpacted: false,
        notes: "",
      };

  return (
    <Container maxWidth="xl">
      <PageHero
        title={id > 0 ? `${project ? project.projectId : id}` : "New Project"}
        subtitle={id > 0 ? `Edit project.` : "Create a new project."}
        showBackButton={true}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={ContractorProjectFormSchema}
        onSubmit={(values, { setSubmitting }) =>
          onSubmit(values, setSubmitting)
        }
        enableReinitialize={true}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormTextField
                  label={"Company Name"}
                  value={values.contractorCompanyName}
                  disabled
                />
                <FormErrorMessage name="name" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name={"projectId"}
                  label={"Project Id"}
                  value={values.projectId}
                />
                <FormErrorMessage name="projectId" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name={"jobSite"}
                  label={"Job Site"}
                  value={values.jobSite}
                />
                <FormErrorMessage name="jobSite" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormSelect
                  name="siteContactId"
                  label={"Site Contact"}
                  value={values.siteContactId ? values.siteContactId : 0}
                  items={[
                    contractorCompanyContacts
                      .filter((c) => c.isSiteContact)
                      .map((contact, index) => (
                        <MenuItem key={index} value={contact.id}>
                          {contact.name} {contact.number}
                        </MenuItem>
                      )),
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormSelect
                  name="officeContactId"
                  label={"Office Contact"}
                  value={values.officeContactId ? values.officeContactId : 0}
                  items={[
                    contractorCompanyContacts
                      .filter((c) => c.isOfficeContact)
                      .map((contact, index) => (
                        <MenuItem key={index} value={contact.id}>
                          {contact.name} {contact.number}
                        </MenuItem>
                      )),
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormSelect
                  name="materialTypeId"
                  label={"Material Type"}
                  value={values.materialTypeId ? values.materialTypeId : 0}
                  items={[
                    materialTypes
                      .filter((c) => c.name)
                      .map((materialType, index) => (
                        <MenuItem key={index} value={materialType.id}>
                          {materialType.name}
                        </MenuItem>
                      )),
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name={"ticketsSold"}
                  label={"Tickets Sold"}
                  value={values.ticketsSold}
                  disabled={true}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name={"approved"}
                  label={"Approved"}
                  value={values.approved}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.isSoilFLOProject}
                      onChange={() =>
                        setFieldValue(
                          "isSoilFLOProject",
                          !values.isSoilFLOProject
                        )
                      }
                    />
                  }
                  label="Is SoilFLO Project"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.overThreshold}
                      onChange={() =>
                        setFieldValue("overThreshold", !values.overThreshold)
                      }
                    />
                  }
                  label="Over Threshold"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.saltImpacted}
                      onChange={() =>
                        setFieldValue("saltImpacted", !values.saltImpacted)
                      }
                    />
                  }
                  label="Salt Impacted"
                />
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  id="notes"
                  name="notes"
                  placeholder="Please enter project notes here..."
                  onChange={(e) => setFieldValue("notes", e.target.value)}
                  value={values.notes ? values.notes : ""}
                  aria-label="notes"
                  minRows={5}
                  className={classes.textArea}
                />
                <FormErrorMessage name="notes" />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  label="Save"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default connect()(ContractorProjectView);
