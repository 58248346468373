import { useMemo, useState } from "react";

import { Box, Grid, Typography } from "@material-ui/core";
import UnitedLogo from "../../assets/logo.png";
import {
  Trailer,
  TrailerSoilQuantity,
  TriaxleSoilQuantity,
} from "../../utils/constants";
import { formatDate, formatTime } from "../../utils/date-helper";
import { RecordViewProps } from "../../interface/RecordViewProps";
import { Ticket } from "../../interface/api/Ticket";
import { Truck } from "../../interface/api/Truck";
import { Setting } from "../../interface/api/Setting";

const RecordView = ({ haulageTicketPayload, styles }: RecordViewProps) => {
  let marginBottom = 12;
  let showLogo = true;
  let fontSize = 16;

  const [ticket] = useState<Ticket | null>(haulageTicketPayload.ticket);
  const [truck] = useState<Truck | null>(haulageTicketPayload.truck);
  const [setting] = useState<Setting | null>(haulageTicketPayload.setting);

  if (styles) {
    marginBottom = styles.marginBottom;
    showLogo = styles.showLogo;
    fontSize = styles.fontSize;
  }

  const contactName = useMemo(
    () =>
      ticket?.isSoilFLOLoad
        ? ticket.siteContactName
        : ticket?.contractorOfficeContactName,
    [ticket, ticket?.isSoilFLOLoad]
  );

  const contactNumber = useMemo(
    () =>
      ticket?.isSoilFLOLoad
        ? ticket.siteContactNumber
        : ticket?.contractorOfficeContactNumber,
    [ticket, ticket?.isSoilFLOLoad]
  );

  const getSoilQuantity = () => {
    if (!ticket) return;

    if (ticket.weight) {
      return (
        <>
          <div>
            <i>Gross:</i> {ticket.weight + Number(truck?.tareWeight)} KG
          </div>
          <div>
            <i>Net:</i> {ticket.weight} KG
          </div>
          <div>
            <i>Tare:</i> {Number(truck?.tareWeight)} KG
          </div>
        </>
      );
    } else {
      return ticket.truckTypeId === Trailer
        ? TrailerSoilQuantity
        : TriaxleSoilQuantity;
    }
  };

  return (
    <Box
      sx={{
        border: "2px solid black",
        padding: "20px",
        marginTop: "20px",
        marginBottom,
      }}
      className="ticket-render"
    >
      <Box
        style={{
          marginBottom,
        }}
      >
        <Grid container>
          <Grid item xs>
            <Typography variant="h5" style={{ marginBottom }}>
              <b>United</b> Soils Management Ltd.
            </Typography>
          </Grid>
          {showLogo && (
            <Grid item xs={4}>
              <img
                src={UnitedLogo}
                style={{ maxWidth: 125, paddingTop: "7px" }}
                alt="United Logo"
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs>
          <Typography style={{ marginBottom }}>Haulage Record</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography style={{ marginBottom, fontSize }}>
            <b>#{ticket?.ticketNumber}</b>
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ marginBottom }}>
        <Typography variant="h6" style={{ marginBottom }}>
          LOADING SITE
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Project Address: </b>
          {ticket?.jobSite}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Soil Quality: </b>
          {`Reg 153/04 Table 2 Agricultural and Other Property Use, except for EC and SAR, with Reg 406/19 exceptions for PAHs and VOCs.`}
        </Typography>
        <Typography
          style={{ marginBottom, textDecoration: "underline", fontSize }}
        >
          {ticket?.isSaltImpacted
            ? ` Load is to be managed as salt-impacted.`
            : ` Load is not identified as salt-impacted.`}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Contractor: </b>
          {ticket?.contractorCompanyName}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Contact Name: </b>
          {contactName}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Number: </b>
          {contactNumber}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Email: </b>
          {ticket?.contractorOfficeContactEmail}
        </Typography>
        {ticket?.materialName && (
          <Typography style={{ marginBottom, fontSize }}>
            <b>Material: </b>
            {ticket?.materialName}
          </Typography>
        )}
        <Typography style={{ marginBottom, fontSize }}>
          <b>Soil Quantity: </b>
          {getSoilQuantity()}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Date Loaded:</b>{" "}
          {ticket && formatDate(ticket.timeLoaded, ticket.isSoilFLOLoad)}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Time Loaded:</b>{" "}
          {ticket && formatTime(ticket.timeLoaded, ticket.isSoilFLOLoad)}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          {`Entry of Time Loaded is Loading Site confirmation that the soil was loaded under supervision, is approved for disposal at United Soils and complies with transport regulations.`}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Hauling Company: </b>
          {ticket?.transportCompanyName}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Driver Name: </b>
          {ticket?.truckDriverName}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Licence Plate Number: </b>
          {ticket?.truckLicensePlate}
        </Typography>
        <Typography variant="h6" style={{ marginBottom }}>
          RECEIVING SITE
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Location: </b>
          {`United Soils Management 14245 Ninth Line, Stouffville, ON`}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Receiving Site Contact: </b>
          {ticket?.isReceived && (
            <span>{setting?.haulageRecordReceiverName}</span>
          )}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Receiving Site Number: </b>
          {ticket?.isReceived && (
            <span>{setting?.haulageRecordReceiverPhone}</span>
          )}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Receiving Site Email: </b>
          {setting?.haulageRecordReceiverEmail}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Receiving Date: </b>{" "}
          {ticket && formatDate(ticket.timeReceived, false)}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          <b>Receiving Time: </b>{" "}
          {ticket && formatTime(ticket.timeReceived, false)}
        </Typography>
        <Typography style={{ marginBottom, fontSize }}>
          {`Entry of Receiving Time is acknowledgement that soil was received and placed at United Soils.`}
        </Typography>
      </Box>
    </Box>
  );
};

export default RecordView;
