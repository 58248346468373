import { useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { debounce } from "lodash";

import { ShortTicketFromSchema } from "./schema/short-ticket-form-schema";
import PageHero from "../shared/page-hero";
import { TicketShortViewProps } from "../../interface/TicketShortViewProps";
import SoilFLOLogo from "../../assets/SoilFLOLogo.png";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getTrucks, setTrucks } from "../../store/transportStore";
import { toast } from "react-toastify";
import { TransportPayload } from "../../interface/api/TransportPayload";
import { Truck } from "../../interface/api/Truck";
import truckService from "../../services/truckService";
import { SetTrucksAction } from "../../interface/redux-types/SetTrucksAction";
import FormErrorMessage from "../shared/form-error-message";
import FormTextField from "../shared/form-text-field";
import { Formik } from "formik";
import FormDateTimePicker from "../shared/form-date-time-picker";
import Button from "../shared/Button";
import InputSubtitle from "../shared/input-subtitle";
import PhoneInput from "../shared/phone-input";

const useStyles = makeStyles({
  center: {
    textAlign: "center",
  },
});

const TicketShortView = ({
  initialValues,
  onShortTicketSubmit,
}: TicketShortViewProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [success, setSuccess] = useState(initialValues.isDispatched);
  const trucks = useAppSelector(getTrucks);

  useMemo(() => {
    const transportPayload: TransportPayload = {
      transportCompanyId: null,
      truck: null,
      transportContact: null,
    };

    truckService.getTrucks(transportPayload).then((response) => {
      if (response.ok) {
        const trucks = response.data as Truck[];
        const SetTrucksAction: SetTrucksAction = {
          trucks,
        };

        dispatch(setTrucks(SetTrucksAction));
      } else {
        toast.error(response.originalError.message);
      }
    });
  }, []);

  const onTruckLicensePlateUpdate = debounce(
    (value: string, setFieldValue: Function) => {
      setFieldValue("truckLicensePlate", value);
      setFieldValue("transportCompanyId", null);
      setFieldValue("truckId", null);

      if (value.length < 7) return;
      const truck = trucks.find(
        (t) =>
          t.licensePlate &&
          t.licensePlate.trim().toLocaleLowerCase() ===
            value.trim().toLocaleLowerCase()
      );
      if (!truck) return;

      setFieldValue("transportCompanyId", truck.transportCompanyId);
      setFieldValue("transportCompanyName", truck.transportCompanyName);
      setFieldValue("truckId", truck.id);
      setFieldValue("truckNumber", truck.number);
      setFieldValue("truckDriverName", truck.driverName);
      setFieldValue("truckDriverPhone", truck.driverNumber);
    },
    10
  );

  if (initialValues.isSoilFLOLoad) {
    return (
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={SoilFLOLogo} width="300" alt="SoilFLO Logo" />
          <h1>SoilFLO Load</h1>
          <Typography className={classes.center}>
            Please manage load information in SoilFLO dashboard.
          </Typography>
        </Box>
      </Container>
    );
  }

  return success == true ? (
    <Container>
      <PageHero
        title="Truck en Route"
        subtitle="You have successfully dispatched the truck."
        showBackButton={true}
      />
    </Container>
  ) : (
    <Container maxWidth="xl">
      <PageHero
        title="Truck Dispatch"
        subtitle="Enter truck information"
        showBackButton={false}
      />
      <Formik
        initialValues={{ ...initialValues, timeLoaded: new Date() }}
        validationSchema={ShortTicketFromSchema}
        onSubmit={(values) => onShortTicketSubmit(values, setSuccess)}
        enableReinitialize={true}
      >
        {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="transportCompanyName"
                  label="Job SIte"
                  value={values.jobSite}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormDateTimePicker
                  label="Time Loaded"
                  value={new Date()}
                  onChange={(date: any) => {
                    setFieldValue("timeLoaded", date, true);
                  }}
                />
                <FormErrorMessage name="timeLoaded" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  label="Truck License Plate"
                  name="truckLicensePlate"
                  inputProps={{
                    style: { textTransform: "uppercase" },
                    maxLength: 7,
                  }}
                  onChange={(e) =>
                    onTruckLicensePlateUpdate(e.target.value, setFieldValue)
                  }
                  value={
                    values.truckLicensePlate ? values.truckLicensePlate : ""
                  }
                />
                <InputSubtitle text="*Please enter license plate" />
                <FormErrorMessage name="truckLicensePlate" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="transportCompanyName"
                  label="Haulage Company"
                  value={values.transportCompanyName}
                />
                <FormErrorMessage name="transportCompanyName" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="truckNumber"
                  label="Truck Number"
                  value={values.truckNumber}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="truckDriverName"
                  label="Truck Driver Name"
                  value={values.truckDriverName}
                />
                <FormErrorMessage name="truckDriverName" />
              </Grid>
              <Grid item xs={12} md={4}>
                <PhoneInput
                  name="truckDriverNumber"
                  label="Truck Driver Number"
                  value={values.truckDriverNumber}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                marginTop: 30,
                marginBottom: 60,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={2}>
                  <Button
                    label="Dispatch"
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  />
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default connect()(TicketShortView);
